import React from "react";
import ReactMarkdown from "react-markdown";
import RouterLink from "./link-renderer";
import gfm from "remark-gfm";
import gemoji from "remark-gemoji";
import propTypes from "prop-types";

function MarkDown(props) {
  return (
    <ReactMarkdown components={{ a: RouterLink }} remarkPlugins={[gfm, gemoji]}>
      {props.children}
    </ReactMarkdown>
  );
}

MarkDown.propTypes = {
  children: propTypes.string,
};

export default MarkDown;
