import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/* Page types */
import Blank from "./pages/Blank";
import Container from "./pages/Container";
import Table from "./pages/Table";
import Exterior from "./pages/Exterior";
import Loading from "./pages/Loading";
import Modal from "./pages/Modal";

/* Page content */
import MarkDown from "./markdown/MarkDown";
import Form from "./Form";

/* Interactive elements */
import Button from "./interactive/Button";
import Image from "./image/Image";
import Ean13 from "./ean/Ean13";
import ServerTable from "./table/ServerTable";
import LinkRenderer from "./markdown/link-renderer";
import ReportGraph from "./reports/ReportGraph";
import KnupuCalendar from "./interactive/Calendar";
import InlineContainer from "./Container/InlineContainer";

function PageGen({ ...props }) {
  const generate = (gen) => {
    return gen.map((item, index) => {
      switch (item.type.toLowerCase()) {
        case "inlinecontainer":
          return (
            <div key={index}>
              <InlineContainer {...item.data} />
            </div>
          );
        case "markdown":
          return (
            <div key={index}>
              <MarkDown>{item.data.md}</MarkDown>
            </div>
          );
        case "linkdownload":
          return (
            <div key={index}>
              <LinkRenderer download={item.data.filename} href={item.data.path}>
                {item.data.title}
              </LinkRenderer>
            </div>
          );
        case "form":
          return (
            <div key={index}>
              <Form key={index + Math.random()} {...item.data} />
            </div>
          );
        case "image":
          return (
            <div key={index}>
              <Image {...item.data} />
            </div>
          );
        case "ean":
          return (
            <div key={index}>
              <Ean13 {...item.data} />
            </div>
          );
        case "servertable":
          return (
            <div key={index}>
              <ServerTable key={item.data.endpoint} {...item.data} />
            </div>
          );
        case "button":
          return <Button key={index} id={index} type="link" {...item.data} />;
        case "reportgraph":
          return (
            <div key={index}>
              <ReportGraph key={item.data.endpoint} {...item.data} />
            </div>
          );
        case "calendar":
          return (
            <div key={index}>
              <KnupuCalendar key={item.data.endpoint} {...item.data} />
            </div>
          );
        default:
          return <p key={index}>Unknown type</p>;
      }
    });
  };

  const actions = () => {
    // Check that path values exists, otherwise return false
    if (
      !props.data ||
      (props.data.actions && typeof props.data.actions !== "object")
    ) {
      return false;
    }

    return (
      typeof props.data.actions === "object" ? props.data.actions : []
    ).map((action, index) => (
      <Button key={index} id={index} type="link" {...action} />
    ));
  };

  const path = () => {
    // Check that path values exists, otherwise return false
    if (
      !props.data ||
      (props.data.path && typeof props.data.path !== "object")
    ) {
      return false;
    }

    const pathLen = props.data.path.length;
    return (typeof props.data.path === "object" ? props.data.path : []).map(
      (dir, index) => (
        <div key={index} className="inline">
          <Link
            to={dir.link}
            className={
              pathLen === index + 1
                ? "text-gray-500 dark:text-white"
                : "text-gray-700 dark:text-gray-400"
            }
          >
            {dir.text}
          </Link>
          {pathLen !== index + 1 ? " / " : ""}
        </div>
      ),
    );
  };

  if (props.type === "client-loading") {
    return <Loading />;
  }

  if (props.type !== "page") {
    console.error("No page type set:", props);
    return "";
  }

  switch (props.data.style.toLowerCase()) {
    case "blank":
      return (
        <Blank
          content={generate(props.gen)}
          path={path()}
          actions={actions()}
          title={props.data.title}
        />
      );
    case "container":
      return (
        <Container
          content={generate(props.gen)}
          path={path()}
          title={props.data.title}
        />
      );
    case "table":
      return (
        <Table
          content={generate(props.gen)}
          servertable={props.data.servertable}
          path={path()}
          actions={actions()}
          title={props.data.title}
        />
      );
    case "exterior":
      return (
        <Exterior
          content={generate(props.gen)}
          path={path()}
          title={props.data.title}
        />
      );
    case "modal":
      return <Modal {...props.data} />;
    default:
      // Default return blank
      console.error(`No match for ${props.data.style}, blank is used.`);
      return (
        <Blank
          content={generate(props.gen)}
          path={path()}
          title={props.data.title}
        />
      );
  }
}

PageGen.propTypes = {
  type: PropTypes.oneOf(["client-loading", "modal", "page", "markdown", "form"])
    .isRequired,
  data: PropTypes.object.isRequired,
  gen: PropTypes.array,
};

PageGen.defaultProps = {
  gen: [],
  data: {},
};

export default PageGen;
