import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import instance from "../../axiosConfig";
import _ from "underscore";
import { Base64 } from "js-base64";
import Table from "./Table";
import Pagination from "./pagination/Pagination";
import TableFilters from "./TableFilters/TableFilters";
import TableActions from "./TableActions";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import store from "../../store/store";

function ServerTable({ endpoint, ...props }) {
  const history = useHistory();
  const location = useLocation();
  const redirect = useSelector((state) => state.redirect.value);

  const urlParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  let options = {
    page: 1,
    params: { perpage: 50, from: 0 },
    filters: {},
  };
  if (urlParams.options) {
    options = JSON.parse(Base64.decode(urlParams.options));
  }

  const [initialState, setInitialState] = useState(true);
  const [body, setBody] = useState([]);
  const [meta, setMeta] = useState({});
  const [head, setHead] = useState([{ name: "", columns: [] }]);
  const [disabled, setDisabled] = useState(false);
  const [params, setParams] = useState(options.params);
  const [page, setPage] = useState(options.page);
  const [filters, setFilters] = useState(options.filters);
  const [selectedList, setSelectedList] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [query, setQuery] = useState(options);

  useEffect(() => {
    if (
      location.state !== undefined &&
      location.state.queryOptions !== undefined
    ) {
      setQuery(location.state.queryOptions);
    } else {
      setQuery({
        page: 1,
        params: { perpage: 50, from: 0 },
        filters: {},
      });
    }
  }, [location]);

  useEffect(() => {
    if (query === undefined) {
      return;
    }
    const tableQueryParams = {
      params: query.params,
      page: query.page,
      filters: query.filters,
    };

    const paramsToHistory = new URLSearchParams({
      options: Base64.encode(JSON.stringify(tableQueryParams)),
    });

    if (location.state?.queryOptions !== tableQueryParams) {
      if (location.search !== "?" + paramsToHistory.toString()) {
        if (
          paramsToHistory.toString() !==
            "options=eyJwYXJhbXMiOnsicGVycGFnZSI6NTAsImZyb20iOjAsIm9yZGVyYnkiOiJuYW1lIiwib3JkZXIiOiJBU0MifSwicGFnZSI6MSwiZmlsdGVycyI6e319" &&
          paramsToHistory.toString() !==
            "options=eyJwYXJhbXMiOnsicGVycGFnZSI6NTAsImZyb20iOjB9LCJwYWdlIjoxLCJmaWx0ZXJzIjp7fX0%3D"
        ) {
          if (paramsToHistory.toString() !== "options=e30%3D") {
            history.push({
              pathname: location.pathname,
              search: paramsToHistory.toString(),
              state: { queryOptions: tableQueryParams },
            });
          }
        }
      }
    }
    loadTable();
  }, [query]);

  useEffect(() => {
    setQuery({
      ...query,
      page: page,
    });
  }, [page]);

  useEffect(() => {
    setQuery({
      ...query,
      filters: filters,
      page: 1,
    });
  }, [filters]);

  useEffect(() => {
    setQuery({
      ...query,
      params: params,
    });
  }, [params]);

  useEffect(() => {
    setQuery({
      ...query,
      page: page,
      filters: filters,
      params: params,
    });
  }, [redirect]);

  useEffect(() => {
    setHead(props.head);
  }, [body, props.head]);

  const updateParams = (newParams) => {
    setParams({
      ...params,
      ...newParams,
    });
  };

  const loadTable = () => {
    if (disabled) {
      return;
    }
    setDisabled(true);

    const queryOptions = query;
    if (queryOptions.page === undefined) {
      queryOptions.page = 1;
    }
    if (queryOptions.params === undefined) {
      queryOptions.params = { perpage: 50, from: 0 };
    }
    if (queryOptions.params.perpage === undefined) {
      queryOptions.params.perpage = options.params.perpage;
    }
    if (queryOptions.filters === undefined) {
      queryOptions.filters = {};
    }

    store.dispatch({ type: "loading/loading" });
    instance
      .get(endpoint, {
        params: {
          ...queryOptions.params,
          from: (queryOptions.page - 1) * queryOptions.params.perpage + 1,
          filters:
            _.size(queryOptions.filters) > 0
              ? Base64.encode(JSON.stringify(queryOptions.filters))
              : null,
        },
      })
      .then(function (response) {
        setBody(response.data.table.body);
        setMeta(response.data.table.meta);
        setDisabled(false);
      })
      .catch(function (error) {
        console.log(error);
        setDisabled(false);
      })
      .then(function () {
        store.dispatch({ type: "loading/done" });
      });
  };

  const selectHandle = (newItem) => {
    switch (typeof newItem) {
      case "object": {
        setSelectedList([...newItem]);
        break;
      }
      case "string": {
        if (selectedList.some((item) => item === newItem)) {
          const list = selectedList.filter((item) => {
            return item !== newItem;
          });
          setSelectedList([...list]);
        } else {
          setSelectedList([...selectedList, newItem]);
        }
        break;
      }
      default: {
        setSelectedList([...selectedList, newItem]);
      }
    }
  };

  const HandleSave = (data) => {
    const formData = { id: data.id };
    props.bodyInfo.forEach((item, index) => {
      if (item.editable) {
        formData[item.name] = data.content[index];
      }
    });
    instance({
      method: props.editEndPoint["hydra:method"],
      url: props.editEndPoint["@id"],
      data: formData,
    })
      .then(() => {
        loadTable();
      })
      .catch((error) => {
        console.error(error);
        loadTable();
      });
  };

  const handleSort = (name) => {
    // Filter clearing
    if (params.order && params.orderby === name && params.order === "DESC") {
      setParams(
        _.pick(params, (value, key) => {
          // Delete order and oderby objects
          return key !== "order" && key !== "orderby";
        }),
      );
      return;
    }
    setParams({
      ...params,
      order:
        params.order && params.orderby === name && params.order === "ASC"
          ? "DESC"
          : "ASC",
      orderby: name,
    });
  };

  if (props.head.length < 1) {
    return "Table header can't be empty.";
  }

  const csv = () => {
    // e.preventDefault()

    const headData = head[0].map((value) => value.title);
    const bodyData = body.map((value) =>
      value.content.map((value2) => {
        if (typeof value2 === "string") {
          return value2.trim();
        }
        return value2;
      }),
    );

    const data = [headData, ...bodyData];
    setCsvData(data);
  };

  return (
    <div key={endpoint} className={disabled ? "animate-pulse cursor-wait" : ""}>
      <TableActions
        selectedList={selectedList}
        body={body}
        onSelectChange={selectHandle}
        onReload={loadTable}
        {...props}
      />
      <TableFilters
        key={endpoint}
        {...props}
        params={params}
        filters_data={filters}
        onParamChange={updateParams}
        onFilterChange={setFilters}
        onCSV={csv}
        csvData={csvData}
      />

      <Table
        head={head}
        body={body}
        onSave={HandleSave}
        selectedList={selectedList}
        onSortChange={handleSort}
        onSelectChange={selectHandle}
        orderBy={params.orderby}
        {...props}
      />

      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 dark:bg-zinc-700">
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {body && (
              <p className="text-sm text-gray-700 dark:text-white">
                {props.translations.table.info
                  .replace("%from", meta.from)
                  .replace("%to", meta.to)
                  .replace("%total", meta.total)}
              </p>
            )}
          </div>
          <Pagination
            perpage={params.perpage}
            meta={meta}
            onPageChange={setPage}
          />
        </div>
      </div>
    </div>
  );
}

ServerTable.propTypes = {
  head: PropTypes.array,
  endpoint: PropTypes.string.isRequired,
  bodyInfo: PropTypes.array,
  editEndPoint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  translations: PropTypes.object,
};

ServerTable.defaultProps = {
  head: [],
  endpoint: "",
  bodyInfo: [],
  method: "POST",
  translations: {},
  actions: [],
  editable: false,
  editEndPoint: {
    "@type": "hydra:Operation",
    "@id": "/attachment/new/item:MUSTA-81C7",
    "schema:name": "NewAttachment",
    "hydra:title": "Upload new file",
    "hydra:method": "PUT",
  },
  selectable: false,
};

export default ServerTable;
